<template>
  <div class="girlbox topmargin">
    <topNav title="男頻"></topNav>
    <!-- 輪播 -->
    <mt-swipe :auto="3000">
      <mt-swipe-item v-for="item in homeSwiper.content" :key="item.id">
        <router-link :to="'/book/' + item.bookId" v-if="item.book">
          <img :src="item.imgUrl" alt="" v-if="item.book" />
        </router-link>
        <a :href="item.url" v-else>
          <img :src="imgURL + item.imgUrl" alt="" />
        </a>
      </mt-swipe-item>
    </mt-swipe>
    <!-- 推薦位 -->
    <ThreeBigShelf
      :shelf="pangone"
      :booklist="pangtwo"
      :title="pangShelf.name"
      :shelfid="pangShelf.id"
    ></ThreeBigShelf>
    <!-- 廣告位 -->
    <router-link  v-for="i in guanggaoShelf.content" :to="'/book/' + i.bookId">
      <img
              :src="i.imgUrl"
              alt=""
              class="imgRecommend"
      />
    </router-link>
    <!-- 推薦位 -->
    <ThreeBigShelf
      :shelf="dashenShelf1"
      :booklist="dashenShelf2"
      :title="dashenShelf.name"
      :shelfid="dashenShelf.id"
    ></ThreeBigShelf>
    <!-- 8本推薦位 -->
    <eightShelf
      :shelf="bianShelf.content"
      :title="bianShelf.name"
      :shelfid="bianShelf.id"
    ></eightShelf>
    <!-- 熱門標籤 -->
    <!-- <div class="ShelfRecommend normalBox">
      <h3 class="clearfix">
        <div class="shelfName fl clearfix">
          <span class="left-line fl"></span>
          <p class="fl">{{ Atags.name }}</p>
        </div>
      </h3>
      <div class="bscroll" ref="slider">
        <ul
          class="item content clearfix"
          :style="'width:' + (137 / 75) * tags.length + 'rem'"
        >
          <li v-for="(item, index) in tags" :key="index" class="fl">
            <img src="~img/tag@2x.png" alt="" />
            <span :style="randomRgb()">{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
import { homeshelf } from "apiurl/book";
/* 引入better-scroll */
import BScroll from "better-scroll";
export default {
  data() {
    return {
      tags: [],
      allShelfList: [],
      homeSwiper: {},
      dashenShelf: {},
      dashenShelf1: [],
      dashenShelf2: [],
      pangShelf: {},
      guanggaoShelf: [],
      bianShelf: {},
      Atags: {},
      pangone: [],
      pangtwo: []
    };
  },
  created() {
    this.$nextTick(() => {
      this.getIdShelf("1201696409477320706");
     /*  this.scroll = new BScroll(this.$refs["slider"], {
        startX: 0,
        click: true,
        scrollX: true,
        // 忽略豎直方向的滾動
        scrollY: false,
        eventPassthrough: "vertical"
      }); */
    });
  },
  methods: {
    getIdShelf(pageId) {
      homeshelf({ pageId }).then(res => {
        if (res.success) {
          this.allShelfList = res.data.shelves;
          this.allShelfList.forEach(item => {
            if (item.id == "yuanshi_Wap_Swipers") {
              this.homeSwiper = item;
            } else if (item.id == "yuanshi_male_popular") {
              this.dashenShelf = item;
              this.dashenShelf1 = item.content.splice(0, 3);
              this.dashenShelf2 = item.content;
            } else if (item.id == "yuanshi_male_editor_recommend") {
              this.pangShelf = item;
              this.pangone = item.content.splice(0, 3);
              this.pangtwo = item.content;
            } else if (item.id == "yaunshi_male_people_like") {
              this.bianShelf = item;
            } else if (item.id == "yuanshi_male_biaoqian") {
              this.Atags = item;
              this.tags = item.content;
            } else if (item.id == "yuanshi_wap_banner") {
              this.guanggaoShelf = item
            }
          });
        }
      });
    },
    // 隨即色
    randomRgb() {
      let R = Math.floor(Math.random() * 255);
      let G = Math.floor(Math.random() * 255);
      let B = Math.floor(Math.random() * 255);
      return {
        color: "rgb(" + R + "," + G + "," + B + ")"
      };
    }
  }
};
</script>

<style lang="stylus" scoped>
/* 標籤 */
.bscroll
    width 100%
    overflow hidden
    margin-bottom 40px
    ul
        li
            width 104px
            height 59px
            position relative
            margin-right 33px
            line-height 59px
            height 59px
            img
                position absolute
                width 104px
                height 59px
                top 0
                left 0
                z-index -1
            span
                display inline-block
                margin-left 36px
                font-size:24px;
                font-weight 500
</style>
